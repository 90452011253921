<template>
  <div style="max-width: 720px; margin:0 auto;">
    <h4 class="form-title">{{ opts.formName }}</h4>

    <el-form label-width="80px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="时间:" prop="createDate" style="float:right;">
            {{ $util.fmtDate(opts.createDate, "yyyy年MM月dd日") }}
          </el-form-item>
          <el-form-item label="单号:" style="float:right;">
            {{ opts.billCode || '' }}
          </el-form-item>
          <div style="clear: both;"></div>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="forms" :model="forms" :rules="rules" class="form-inline" label-position="right">
      <el-row>
        <el-col :xs="24">
          <el-form-item label="加班类型" prop="dataType">
            <el-select v-model="forms.dataType" placeholder="请选择加班类型" :disabled="!startNode">
              <el-option label="日常加班" value="日常加班" />
              <el-option label="周末加班" value="周末加班" />
              <el-option label="法定加班" value="法定加班" />
              <el-option label="其他" value="其他" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :sm="12">
          <el-form-item label="开始日期" prop="startDate">
            <el-date-picker v-model="forms.startDate" type="date" :disabled-date="disabledStartDate"
              @blur="dateChange()" :disabled="!startNode" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :sm="12">
          <el-form-item label="开始时间" prop="startTime">
            <el-time-select v-model="forms.startTime" start="00:00" step="00:30" end="23:59" style="max-width:120px" :disabled="!startNode" />
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item label="结束时间" prop="endTime">
            <el-time-select v-model="forms.endTime" start="00:00" step="00:30" end="24:00" style="max-width:120px" :disabled="!startNode" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="加班时长" prop="totalHour">
        <el-input-number v-model="forms.totalHour" :min="1" :max="10" step="0.5" step-strictly style="max-width:150px;"
          placeholder="0.00" :disabled="!startNode" ></el-input-number> &nbsp;&nbsp;&nbsp;(单位小时h)
      </el-form-item>
      <el-form-item label="加班事由" prop="reasons">
        <el-input v-model="forms.reasons"  :disabled="!startNode"/>
      </el-form-item>
      <el-form-item label="备注">
        <b>关于平日加班</b>
        <p style="width:100%;">周一至周五工作日，晚上19:00前不计调休时间，晚上19:00后开始记录加班时间为调休时间，以最少1小时起按小时（整点）数为计算单位，加班至晚上22:30后可报销打车费用，并补贴夜宵费用50元。</p>
        <b>关于周末加班</b>
        <p style="width:100%;">周六下午，周日全天为非上班时间加班的，都计加班时间为调休时间，最少1 小时起按小时（整点）数为计算单位，中午12:00-13:00晚上18:00-19:00为吃饭时间不计入调休，补贴费用为100元/天。</p>
        <b>关于法定节假日加班</b>
        <p style="width:100%;">加班时间补贴费用为200元/天，计加班时间。</p>
      </el-form-item>
    </el-form>
  </div>
</template>
      
<script>
export default {
  name: "hrovertime",
  components: {},
  data() {
    return {
      initOpts: {
        formType: 'JBSQ',
        formCode: 'hrovertime',
        formName: '加班申请单',
        flowCode: 'hr-overtime',
        status: 0,
      },
      forms: this.formdata,
      rules: {
        dataType: [
          { required: true, message: "请选择加班单类型", trigger: "blur" },
        ],
        startDate: [
          { required: true, message: "请选择要加班的开始日期", trigger: "blur" }
        ],
        startTime: [
          { required: true, message: "请选择要加班的开始时间", trigger: "blur" }
        ],
        endTime: [
          { required: true, message: "请选择要加班的结束时间", trigger: "blur" }
        ],
        totalHour: [
          { required: true, message: "请选择要加班的小时数", trigger: "blur" }
        ],
        reasons: [
          { required: true, message: "请输入加班的事由", trigger: "blur" }
        ]
      },
      dict: {},
    };
  },
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
    opts: {
      type: Object,
      default: {}
    },
    formdata: {
      type: Object,
      default: { details: [{}, {}, {}] },
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getInitOpts() {
      return this.initOpts;
    },
    async formCheck() {
      let p = this.$refs['forms'].validate()
      try {
        await p
      } catch (error) {
        //此处的error为表单校验失败的字段
        if (error) return false;
      }
      console.log(p)
      return true;
    },
    getForms() {
      return this.forms;
    },
    removeItem(index) {
      this.forms.details.splice(index, 1);
      console.log(this.forms.details)
    },

    // 设置开始日期 的范围
    disabledStartDate(time) {
      // 只能选择当前时间的前一个月的和当天之前的
      let nowDate = new Date();
      let startRange = new Date().setTime(nowDate.getTime() - (24 * 60 * 60 * 1000 * 15));
      let endRange = new Date().setTime(nowDate.getTime() + (24 * 60 * 60 * 1000 * 2));
      return (
        time.getTime() < startRange || time.getTime() > endRange
      )
    },
    dateChange() {

    }
  },
  created() {
    let that = this;

    if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

  },
  watch: {
    formdata: function (newValue, oldValue) {
      console.log(newValue);
    },
  },
  computed: {
    startNode() {
      //草稿或者退回状态
      if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '') {
        return true;
      }
      return false;
    },
    endNode() {
      if (this.initOpts.status == 50) {
        return true;
      }
      return false;
    }
  }

};
</script>
      
<style scoped></style>
      